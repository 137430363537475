<template>
    <div class="card">
        <div class="card-body">
            <p class="introduction">{{ introduction }}</p>
            <p v-html="getContent()"></p>

            <a tabindex="0" class="btn btn-link btn-showmore" @keyup.enter="showMore = !showMore" @click="showMore = !showMore" v-if="btnShowMore" style="text-decoration: none;">
            <p>{{ showMore ? 'Weniger anzeigen' : 'Mehr anzeigen'}}</p>
            <i class="material-icons" aria-hidden="true">{{ showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
        </a>
    </div>

    <div class="card-buttons" v-if="tags!=null">
        <button
        tabindex="-1"
        class="btn btn-2 icon-before"
        v-for="(tag,index) in tags"
        :key="index">
        <i class="material-icons" aria-hidden="true">check_circle</i>
        {{ tag }}
    </button>
</div>
</div>
</template>

<script>

    import {smartSubStr} from '@/utils/helpers';

    export default {
        name: "CardSimple",
        props: {
            introduction: {
                type: String,
                required: false,
            },
            description: {
                type: String,
                required: false
            },
            tags: {
                type: Array,
                default: null,
                required: false
            }
        },
        data() {
            return {
                maxLength: 300,
                showMore: false
            }
        },
        computed: {
            btnShowMore() {
                if (this.description.length > this.maxLength) {
                    return true;
                }
                return false;
            }
        },
        methods: {
            smartSubStr,
            getContent(){
                return this.showMore?this.description:this.limitedString();
            },
            limitedString() {
                if (this.description.length > this.maxLength) {
                    return smartSubStr(this.description, this.maxLength) + '...';
                }

                return this.description;
            },
        }
    }
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.card{
    position: relative;
    border-radius: 5px;
    border-bottom-left-radius: 30px;
    background: #fff;
    border: none;
    padding: 40px;
    box-shadow: -5px 5px 15px 0px rgba($color: #000, $alpha: 0.2);

    &-body{
        padding: 0;
        margin: 0px 0px 15px 0;

        p {
            line-height: 24px;
            font-size: 14px;
        }

        .introduction {
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 20px;
        }
        
        .btn-showmore {
            display: flex;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            text-transform: capitalize;
            color: $darkGray;
            margin: 15px 0 0 0;

            i.material-icons{
                position: relative;
            }

            &:hover,
            &:active,
            &:focus {
                text-decoration: none;
            }
        }
    }

    &-buttons {
        button.btn {
            cursor: auto;
            margin-right: 10px;
            margin-bottom: 10px;

            @media (max-width: 768px) {
                margin-bottom: 10px
            }
        }
    }

    @media (max-width: 500px) {
        padding: 20px;
    }
}

.card:first-child{
    margin-top: 0;
}

</style>
